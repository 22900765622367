import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { Loader2 } from 'lucide-react';

Modal.setAppElement('#root');


const ImageWithSpinner: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader2 className="animate-spin text-primary" size={48} />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-contain ${isLoading ? 'opacity-0' : 'opacity-100'}`}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};
interface MoneyAndReformAttributes {
  name_fr: string;
  name_ar: string;
  money_types: {
    data: {
      id: number;
      attributes: {
        name_fr: string;
        name_ar: string;
        fr_image: {
          data: {
            attributes: {
              url: string;
            };
          };
        };
        ar_image: {
          data: {
            attributes: {
              url: string;
            };
          };
        };
        money_versions: {
          data: {
            attributes: {
              year: string;
              old_name: string;
              fr_image: {
                data: {
                  attributes: {
                    url: string;
                  };
                };
              };
              ar_image: {
                data: {
                  attributes: {
                    url: string;
                  };
                };
              };
            };
          }[];
        };
      };
    }[];
  };
}

interface MoneyAndReform {
  id: number;
  attributes: MoneyAndReformAttributes;
}



export const MoneyTypeCard: React.FC<{
  moneyType: MoneyAndReformAttributes['money_types']['data'][0];
  language: string;
  t: (key: string) => string;
}> = ({ moneyType, language, t }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg shadow-md border border-primary overflow-hidden transition-transform duration-300 hover:shadow-xl">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2">
          {language === 'ar' ? moneyType.attributes.name_ar : moneyType.attributes.name_fr} {t('MRU')}
        </h2>
        <p className="text-sm text-gray-600 mb-4">{t('Passez la souris pour voir l\'autre côté')}</p>
      </div>
      <div className="relative w-full h-52 [perspective:1000px] group">
        <div className="absolute w-full h-full transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
          <div className="absolute w-full h-full backface-hidden">
            <ImageWithSpinner
              src={`https://api.sitewebbcm.com${language === 'fr' ? moneyType.attributes.fr_image.data.attributes.url : moneyType.attributes.ar_image.data.attributes.url}`}
              alt="Front"
            />
          </div>
          <div className="absolute w-full h-full [transform:rotateY(180deg)] backface-hidden">
            <ImageWithSpinner
              src={`https://api.sitewebbcm.com${language === 'fr' ? moneyType.attributes.ar_image.data.attributes.url : moneyType.attributes.fr_image.data.attributes.url}`}
              alt="Back"
            />
          </div>
        </div>
      </div>
      {moneyType.attributes.money_versions.data.length > 0 && (
        <div className="p-4">
          <button
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-green-700 transition-colors duration-300"
            onClick={() => navigate(`/money-historique/${moneyType.id}`)}
          >
            {t('Historiques')}
          </button>
        </div>
      )}
    </div>
  );
};
export function MoneyTypePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = useState(true);
  const [moneyAndReform, setMoneyAndReform] = useState<MoneyAndReform | null>(null);

  useEffect(() => {
    if (id) {
      fetch(`https://api.sitewebbcm.com/api/money-and-reforms/${id}?populate=money_types.fr_image,money_types.ar_image,money_types.money_versions.fr_image,money_types.money_versions.ar_image`, {
        headers: {

        }
      })
        .then(response => response.json())
        .then((data: { data: MoneyAndReform }) => {
          setMoneyAndReform(data.data);
        })
        .catch(error => {
          console.error('Error fetching money and reform:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  if (!moneyAndReform) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-gray-600">{t('No data found')}</p>
      </div>
    );
  }

  const { attributes } = moneyAndReform;



 
  const sortedMoneyTypes = attributes.money_types.data.sort((a, b) => {
    const nameA = Number(a.attributes.name_fr.toLowerCase());
    const nameB = Number(b.attributes.name_fr.toLowerCase());
  
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  });
  return (
    <div className=" min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          {language === 'ar' ? attributes.name_ar : attributes.name_fr}
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
          {sortedMoneyTypes.map((moneyType) => (
            <MoneyTypeCard
              key={moneyType.id}
              moneyType={moneyType}
              language={language}
              t={t}
            />
          ))}
        </div>
      </div>
    </div>
  );
}