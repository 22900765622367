import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { ArrowIcon } from './home_page';
import { ScrollInChildren } from '../components/scroll_in_animation';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';


Modal.setAppElement('#root');

interface FormData {
    email: string;
    password: string;
    confirm_password: string;
    nid: string;
    interest: string;
}

interface FormErrors {
    email?: boolean;
    password?: boolean;
    confirm_password?: boolean;
    nid?: boolean;
    interest?: boolean;
}

export function CareerPage() {
    const { language } = useLanguage();
    const { t } = useTranslation();
    const isRTL = language === 'ar';
    const renderComponent = () => {
        switch (selectedComponent) {
            case 1:
                return <Offers user={user} />;
            case 2:
                return <OffersInterns user={user} />;
            case 3:
                return <InternshipPrograms />;
            case 4:
                return <Communiques />;
            case 5:
                return <RecruitmentAndFAQ />;
            case 6:
                return <Contact user={user} />;
            default:
                return <Home />;
        }
    };

    const [loadingMe, setLoadingMe] = useState(true);
    const [selectedComponent, setSelectedComponent] = useState(0);
    const [isSignUp, setIsSignUp] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: '',
        confirm_password: '',
        nid: '',
        interest: ''
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [shake, setShake] = useState<FormErrors>({});
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [apiError, setApiError] = useState<string | null>(null);

    useEffect(() => {
        fetchCurrentCandidate();
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setFormErrors({
            ...formErrors,
            [name]: !value
        });
        setShake({
            ...shake,
            [name]: false
        });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const errors: FormErrors = {};
        const shakeErrors: FormErrors = {};

        Object.keys(formData).forEach((key) => {
            if (!formData[key as keyof FormData]) {
                errors[key as keyof FormData] = true;
                shakeErrors[key as keyof FormData] = true;
            }
        });
        setFormErrors(errors);
        setShake(shakeErrors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            setApiError(null);

            try {
                const response = await fetch('https://api.sitewebbcm.com/api/auth-candidats/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
                    },
                    body: JSON.stringify(formData),
                });

                if (!response.ok) {
                    if (response.status === 400) {
                        toast.error(t('Cet e-mail existe déjà'), {
                            duration: 4000,
                            position: 'top-left',
                            style: {
                                borderRadius: '10px',
                                background: '#25AE09',
                                color: '#fff',
                                top: '200px',
                                position: 'relative',
                                left: '80px'
                            }
                        });
                    }
                    throw new Error('Failed to register');
                }

                const data = await response.json();
                localStorage.setItem('token-candidate', data.token);
                setUser(data.candidate);
                toast.success(t('Inscrit avec succès'), {
                    duration: 4000,
                    position: 'top-left',
                    style: {
                        borderRadius: '10px',
                        background: '#25AE09',
                        color: '#fff',
                        top: '200px',
                        position: 'relative',
                        left: '80px'
                    }
                });
            } catch (error: any) {
                setApiError('Registration failed. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://api.sitewebbcm.com/api/auth-candidats/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                }),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const body = await response.json();
                    if (body.error.message === 'INVALID_CREDENTIALS') {
                        toast.error(t('Identifiants incorrects'), {
                            duration: 4000,
                            position: 'top-left',
                            style: {
                                borderRadius: '10px',
                                background: '#25AE09',
                                color: '#fff',
                                top: '200px',
                                position: 'relative',
                                left: '80px'
                            }
                        });
                    }
                    if (body.error.message === 'VERIFICATION_REQUIRED') {
                        toast.error(t('Vous devez valider votre adresse e-mail'), {
                            duration: 4000,
                            position: 'top-left',
                            style: {
                                borderRadius: '10px',
                                background: '#25AE09',
                                color: '#fff',
                                top: '200px',
                                position: 'relative',
                                left: '80px'
                            }
                        });
                    }
                    throw new Error('Failed to login');
                }
            }

            const data = await response.json();
            localStorage.setItem('token-candidate', data.token);
            setUser(data.candidate);
            setLoading(false);
            toast.success(t('Login avec succès'), {
                duration: 4000,
                position: 'top-left',
                style: {
                    borderRadius: '10px',
                    background: '#25AE09',
                    color: '#fff',
                    top: '200px',
                    position: 'relative',
                    left: '80px'
                }
            });
        } catch (error) {
            setLoading(false);
            setApiError('Login failed. Please try again.');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token-candidate');
        setUser(null);
    }

    const fetchCurrentCandidate = async () => {
        const token = localStorage.getItem('token-candidate');
        if (!token) {
            setLoadingMe(false);
            return;
        }

        try {
            const response = await fetch('https://api.sitewebbcm.com/api/mecand', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
                },
                body: JSON.stringify({ token })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch candidate data');
            }

            const data = await response.json();
            setUser(data);
            setLoadingMe(false);
        } catch (error) {
            setLoadingMe(false);
            console.error('Error fetching candidate data:', error);
        }
    };

    const renderInput = (label: string, name: keyof FormData, type: string = 'text') => (
        <div>
            <label className="block mb-1 text-sm font-medium">{t(label)}</label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleInputChange}
                className={classNames(
                    'bg-gray-50 border-2 border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2',
                    { 'border-red-500': formErrors[name] },
                    { 'shake': shake[name] }
                )}
                placeholder={t(label)}
            />
        </div>
    );

    return (
        !loadingMe ? (
            <div className='flex flex-col md:flex-row gap-4  justify-center mt-12 mb-48 px-4'>
                <section>
                    <div className="flex flex-col items-center px-4 py-6 mx-auto md:h-screen lg:py-0">
                        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                            <div className="p-4 space-y-4 md:space-y-4 sm:p-6">
                                <div className='flex justify-between items-center'>
                                    <h1 className="text-lg font-bold leading-tight tracking-tigh text-primary">
                                        {t('Espace Candidat')}
                                    </h1>
                                    {user && (
                                        <button type="button" className=" text-white bg-red-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={handleLogout}>{t('Se deconnecter')}</button>
                                    )}
                                </div>
                                {user ? (
                                    <div className='flex flex-col items-center'>
                                        <div className='flex justify-between items-center'>
                                            <p className="">
                                                {t('Email')} :
                                                <span className='text-green-600 pl-2 font-bold'>
                                                    {user.email}
                                                </span>
                                            </p>
                                        </div>
                                        {!user?.is_email_verified && (
                                            <p className="text-black font-bold pl-2 mt-4">
                                                {t('Veuillez valider votre email!')}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {isSignUp ? (
                                            <form className="space-y-3 md:space-y-4" onSubmit={handleSubmit}>
                                                {renderInput('Email', 'email', 'email')}
                                                {renderInput('Mot de passe', 'password', 'password')}
                                                {renderInput('Confirmation du mot de passe', 'confirm_password', 'password')}
                                                {renderInput('Numéro National d\'Identification', 'nid')}
                                                <div>
                                                    <label className="block mb-1 text-sm font-medium">
                                                        {t('Intérêt')}
                                                    </label>
                                                    <select
                                                        name="interest"
                                                        value={formData.interest}
                                                        onChange={handleInputChange}
                                                        className={classNames(
                                                            'bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2',
                                                            { 'border-red-500': formErrors.interest },
                                                            { 'shake': shake.interest }
                                                        )}
                                                    >
                                                        <option value="">{t('Sélectionnez votre intérêt')}</option>
                                                        <option value="Stages d\'initiation professionnelle d\'excellence">{t('Stages d\'initiation professionnelle d\'excellence')}</option>
                                                        <option value="Stages de fin d\'études">{t('Stages de fin d\'études')}</option>
                                                    </select>
                                                </div>
                                                {loading ? (
                                                    <div className="flex justify-center items-center">
                                                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status">
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <button type="submit" className="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center">{t('Sign up')}</button>
                                                )}
                                                <p className="text-sm font-light">
                                                    {t('Vous avez déjà un compte?')} <a href="#" className="text-primary font-bold hover:underline" onClick={() => setIsSignUp(false)}>{t('Se connecter')}</a>
                                                </p>
                                            </form>
                                        ) : (
                                            <form className="space-y-3 md:space-y-4" onSubmit={handleSubmit}>
                                                {renderInput('Email', 'email', 'email')}
                                                {renderInput('Mot de passe', 'password', 'password')}
                                                {loading && (
                                                    <div className="flex justify-center items-center">
                                                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status">
                                                        </div>
                                                    </div>
                                                )}
                                                {!loading && (
                                                    <button type="button" className="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={handleLogin}>{t('Sign in')}</button>
                                                )}
                                                <p className="text-sm font-light">
                                                    {t('Vous n\'avez pas encore de compte?')} <a href="#" className="text-primary font-bold hover:underline" onClick={() => setIsSignUp(true)}>{t('S\'inscrire')}</a>
                                                </p>
                                            </form>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-1/2">
                    <div>
                        <nav className="flex items-center gap-4 mb-3">
                            <a onClick={() => setSelectedComponent(1)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 1 })}>{t('Offres d\'emploi')}</a>
                            <a onClick={() => setSelectedComponent(2)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 2 })}>{t('Offres de stages')}</a>
                            <a onClick={() => setSelectedComponent(3)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 3 })}>{t('Programmes de stages')}</a>
                            <a onClick={() => setSelectedComponent(4)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 4 })}>{t('Communiqués')}</a>
                            <a onClick={() => setSelectedComponent(5)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 5 })}>{t('FAQ')}</a>
                            <a onClick={() => setSelectedComponent(6)} className={classNames("cursor-pointer font-bold text-gray-700 hover:text-white hover:bg-primary border-2 border-primary p-2 rounded-md text-xs", { 'bg-primary text-white': selectedComponent === 6 })}>{t('Contact')}</a>
                        </nav>
                        {renderComponent()}
                    </div>
                </section>
            </div>
        ) : (
            <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-16 h-16 border-8 rounded-full border-primary border-t-transparent" role="status">
                </div>
            </div>
        )
    );
}




interface OfferAttributes {
    title_fr: string;
    title_ar: string;
    nbr_poste: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    is_active: boolean;
}

interface Offer {
    id: number;
    attributes: OfferAttributes;
}


function Offers({user }: any) {



    console.log('user  ... ' , user)
    const { language } = useLanguage();
    const { t } = useTranslation();
    const isRTL = language === 'ar';
    const [offers, setOffers] = useState<Offer[]>([]);
    const [modalOpenID, setIsModalOpen] = useState<number | null>(null);
    const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
    const [documents, setDocuments] = useState<File[]>([]);

    useEffect(() => {
        fetch('https://api.sitewebbcm.com/api/offers?filters[type]=JOB&filters[is_active]=true&populate=*', {
            headers: {
                'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
            }
        })
            .then(response => response.json())
            .then((data: { data: Offer[] }) => setOffers(data.data))
            .catch(error => console.error('Error fetching offers:', error));
    }, []);

    const openModal = (offerId: number) => {


        console.log('open modal ')
        setSelectedOfferId(offerId);
        setIsModalOpen(offerId);
    };

    const closeModal = () => {
        setIsModalOpen(null);
        setSelectedOfferId(null);
        setDocuments([]);
    };

    const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setDocuments(Array.from(e.target.files).concat(documents));
        }
    };

    const handleApply = async () => {
        if (!selectedOfferId || documents.length === 0) return;

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            candidat: user?.id,
            offer: selectedOfferId
        }));

        documents.forEach(doc => {
            formData.append('files.documents', doc);
        });

        try {
            const response = await fetch('https://api.sitewebbcm.com/api/apply-offers', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
                },
                body: formData
            });

            if (response.ok) {
                
                toast.success(t('Postuler avec succès'), {
                    duration: 4000,
                    position: 'top-left',
                    style: {
                        borderRadius: '10px',
                        background: '#25AE09',
                        color: '#fff',
                        top: '200px',
                        position: 'relative',
                        left: '80px'
                    }
                });
                closeModal();
            } else {

                toast.error(t('Erreur lors de postuler'), {
                    duration: 4000,
                    position: 'top-left',
                    style: {
                        borderRadius: '10px',
                        background: '#25AE09',
                        color: '#fff',
                        top: '200px',
                        position: 'relative',
                        left: '80px'
                    }
                });
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className='flex flex-col gap-4'>
            {offers.map(offer => (
                <div key={offer.id} onClick={() => { }} className={`border-strokeGray flex cursor-pointer items-center justify-between gap-x-4 rounded-lg border bg-slate-100 p-3 shadow-md`}>
                    <div className="flex flex-col gap-2">
                        <p className="text-darkGray font-Montserrat font-semibold">{
                            language === 'ar' ? offer.attributes.title_ar :
                                offer.attributes.title_fr}</p>
                        <div className='flex gap-2 items-center justify-center'>
                            <span>{t('Date')} : {new Date(offer.attributes.createdAt).toLocaleDateString()}</span>
                            <span className='border-l-2 border-primary px-2'>
                                {t('Nombre de postes')} : {offer.attributes.nbr_poste}</span>
                        </div>
                    </div>

                    {
                        user && modalOpenID != offer.id && <button type="button" className="text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={() => openModal(offer.id)}>{t('Apply')}</button>
                    }

                    

              
                      {
                        modalOpenID === offer.id &&
                        <Modal
                            isOpen={modalOpenID === offer.id}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Apply for Internship"
                        >
                            <h2 className="text-lg font-bold mb-4">{t('Documents')}</h2>
                            <input type="file" multiple onChange={handleDocumentChange} className="mb-4" />
                            <button type="button" className="text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2" onClick={handleApply}>{t('Postuler')}</button>
                            <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 border border-gray-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={closeModal}>{t('Annuler')}</button>
                        </Modal>
                    }
                  
                </div>
            ))}

            {/* <Modal
            > */}
               
            {/* </Modal> */}
        </div>
    );
}
function Home() {
    const { t } = useTranslation();

    return (
        <div>
            <h1 className="text-lg font-bold leading-tight tracking-tight text-primary mb-2">{t('DEAL Employeur/Collaborateur')}</h1>
            <hr className="border-primary border-2 w-full" />
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h2 className="text-base font-semibold text-primary">{t('Notre Promesse employeur')}</h2>
                    <ul className="list-disc list-inside green-bullets">
                        <li>{t('Un environnement de travail sécurisant et exigeant')}</li>
                        <li>{t('Des dispositifs équitables de rémunération et de développement des compétences')}</li>
                        <li>{t('Un package d\'avantages sociaux attrayants')}</li>
                        <li>{t('Une reconnaissance de la valeur ajoutée apportée et du mérite individuel')}</li>
                        <li>{t('Des parcours professionnels riches et diversifiés')}</li>
                        <li>{t('Un management par objectif stimulant intellectuellement')}</li>
                        <li>{t('Des équipes attachées à l\'image d\'une institution prestigieuse et soudées autour d\'une ambition de modernisation')}</li>
                    </ul>
                </div>
                <div>
                    <h2 className="text-base font-semibold text-primary">{t('L\'engagement du collaborateur')}</h2>
                    <ul className="list-disc list-inside green-bullets">
                        <li>{t('Une implication totale pour relever les challenges d\'orientation et de régulation')}</li>
                        <li>{t('La volonté de se dépasser pour tendre vers l\'excellence et être un référent')}</li>
                        <li>{t('De la solidarité et une volonté de coopérer et de donner le meilleur de soi-même au profit de la performance collective')}</li>
                        <li>{t('De la souplesse, des efforts d\'adaptation aux évolutions (technologiques et d\'organisation, ...)')}</li>
                        <li>{t('Le souci permanent de préserver son employabilité par l\'amélioration continue de ses compétences')}</li>
                        <li>{t('Un comportement professionnel empreint de loyauté, d\'éthique et de déontologie')}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}


interface InternshipOfferAttributes {
    title_fr: string;
    title_ar: string;
    nbr_poste: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    is_active: boolean;
}

interface InternshipOffer {
    id: number;
    attributes: InternshipOfferAttributes;
}


function OffersInterns({ user }: { user: any }) {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [internshipOffers, setInternshipOffers] = useState<InternshipOffer[]>([]);
    const [modalOpenID, setIsModalOpen] = useState<number | null>(null);
    const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
    const [documents, setDocuments] = useState<File[]>([]);

    useEffect(() => {
        fetch('https://api.sitewebbcm.com/api/offers?filters[type]=INTERN&filters[is_active]=true&populate=*', {
            headers: {
                'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
            }
        })
            .then(response => response.json())
            .then((data: { data: InternshipOffer[] }) => setInternshipOffers(data.data))
            .catch(error => console.error('Error fetching internship offers:', error));
    }, []);

    const openModal = (offerId: number) => {
        setSelectedOfferId(offerId);
        setIsModalOpen(offerId);
    };

    const closeModal = () => {
        setIsModalOpen(null);
        setSelectedOfferId(null);
        setDocuments([]);
    };

    const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setDocuments(Array.from(e.target.files).concat(documents));
        }
    };

    const handleApply = async () => {
        if (!selectedOfferId || documents.length === 0) return;

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            candidat: user?.id,
            offer: selectedOfferId
        }));

        documents.forEach(doc => {
            formData.append('files.documents', doc);
        });

        try {
            const response = await fetch('https://api.sitewebbcm.com/api/apply-offers', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
                },
                body: formData
            });

            if (response.ok) {
                toast.success(t('Postuler avec succès'), {
                    duration: 4000,
                    position: 'top-left',
                    style: {
                        borderRadius: '10px',
                        background: '#25AE09',
                        color: '#fff',
                        top: '200px',
                        position: 'relative',
                        left: '80px'
                    }
                });
                closeModal();
            } else {
                toast.error(t('Erreur lors de postuler'), {
                    duration: 4000,
                    position: 'top-left',
                    style: {
                        borderRadius: '10px',
                        background: '#25AE09',
                        color: '#fff',
                        top: '200px',
                        position: 'relative',
                        left: '80px'
                    }
                });
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className='flex flex-col gap-4'>
            {internshipOffers.map(offer => (
                <div key={offer.id} onClick={() => { }} className={`border-strokeGray flex cursor-pointer items-center justify-between gap-x-4 rounded-lg border bg-slate-100 p-3 shadow-md`}>
                    <div className="flex flex-col gap-2">
                        <p className="text-darkGray font-Montserrat font-semibold">{
                            language === 'ar' ? offer.attributes.title_ar :
                                offer.attributes.title_fr}</p>
                        <div className='flex gap-2 items-center justify-center'>
                            <span>{t('Date')} : {new Date(offer.attributes.createdAt).toLocaleDateString()}</span>
                            <span className='border-l-2 border-primary px-2'>
                                {t('Nombre de postes')} : {offer.attributes.nbr_poste}</span>
                        </div>
                    </div>

                    {
                        user && modalOpenID != offer.id && <button type="button" className="text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={() => openModal(offer.id)}>{t('Apply')}</button>
                    }

                    {
                        modalOpenID === offer.id &&
                        <Modal
                            isOpen={modalOpenID === offer.id}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Apply for Internship"
                        >
                            <h2 className="text-lg font-bold mb-4">{t('Documents')}</h2>
                            <input type="file" multiple onChange={handleDocumentChange} className="mb-4" />
                            <button type="button" className="text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2" onClick={handleApply}>{t('Postuler')}</button>
                            <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 border border-gray-300 font-medium rounded-lg text-sm px-4 py-2 text-center" onClick={closeModal}>{t('Annuler')}</button>
                        </Modal>
                    }
                </div>
            ))}
        </div>
    );
}


interface ProgramSectionProps {
    title: string;
    description: string;
    objectives?: string[];
    criteria?: string[];
    additionalInfo?: string;
}

interface InternshipProgramsProps {
    // Add any props if needed
}

function ProgramSection({ title, description, objectives, criteria, additionalInfo }: ProgramSectionProps) {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col gap-2'>
            <h1 className="text-lg font-bold leading-tight tracking-tight text-primary">
                {title}
            </h1>
            <hr className="border-primary border-2 w-full" />
            <p className="text-gray-700">{description}</p>
            {objectives && (
                <div>
                    <h2 className="text-base font-semibold text-primary mt-2">{t('Les objectifs visés par ce programme sont :')}</h2>
                    <ul className="list-disc list-inside green-bullets">
                        {objectives.map((objective, index) => (
                            <li key={index}>{objective}</li>
                        ))}
                    </ul>
                </div>
            )}
            {criteria && (
                <div>
                    <h2 className="text-base font-semibold text-primary mt-2">{t('Les critères d\'éligibilités sont :')}</h2>
                    <ul className="list-disc list-inside green-bullets">
                        {criteria.map((criterion, index) => (
                            <li key={index}>{criterion}</li>
                        ))}
                    </ul>
                </div>
            )}
            {additionalInfo && (
                <p className="text-gray-700 mt-2">{additionalInfo}</p>
            )}
        </div>
    );
}




function InternshipPrograms({ }: InternshipProgramsProps) {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col gap-6'>
            <ProgramSection
                title={t('InternshipPrograms.InitiationStage.title')}
                description={t('InternshipPrograms.InitiationStage.description')}
                objectives={[
                    t('InternshipPrograms.InitiationStage.objectives.0'),
                    t('InternshipPrograms.InitiationStage.objectives.1'),
                    t('InternshipPrograms.InitiationStage.objectives.2')
                ]}
            />

            <ProgramSection
                title={t('InternshipPrograms.FinalStages.title')}
                description={t('InternshipPrograms.FinalStages.description')}
                objectives={[
                    t('InternshipPrograms.FinalStages.objectives.0'),
                    t('InternshipPrograms.FinalStages.objectives.1')
                ]}
                criteria={[
                    t('InternshipPrograms.FinalStages.criteria.0'),
                    t('InternshipPrograms.FinalStages.criteria.1'),
                    t('InternshipPrograms.FinalStages.criteria.2')
                ]}
                additionalInfo={t('InternshipPrograms.FinalStages.additionalInfo')}
            />

            <ProgramSection
                title={t('InternshipPrograms.YoungProfessionalsProgram.title')}
                description={t('InternshipPrograms.YoungProfessionalsProgram.description')}
                objectives={[
                    t('InternshipPrograms.YoungProfessionalsProgram.objectives.0'),
                    t('InternshipPrograms.YoungProfessionalsProgram.objectives.1')
                ]}
                criteria={[
                    t('InternshipPrograms.YoungProfessionalsProgram.criteria.0'),
                    t('InternshipPrograms.YoungProfessionalsProgram.criteria.1')
                ]}
                additionalInfo={t('InternshipPrograms.YoungProfessionalsProgram.additionalInfo')}
            />
        </div>
    );
}




interface CommuniquesAttributes {
    title_fr: string;
    title_ar: string;
    content_fr: string;
    content_ar: string;
    top_image: string;
    files_fr: string;
    files_ar: string;
}

interface Communiques {
    id: number;
    attributes: CommuniquesAttributes;
}

function Communiques() {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [communiques, setCommuniques] = useState<Communiques[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetch('https://api.sitewebbcm.com/api/press-relases-interns?populate=*', {
            headers: {
                'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
            }
        })
            .then(response => response.json())
            .then((data: { data: Communiques[] }) => setCommuniques(data.data))
            .catch(error => console.error('Error fetching communiques:', error));
    }, []);

    return (
        <div className='flex flex-col gap-4 mt-4'>
            {communiques.map((communique) => (
                <div key={communique.id} className="flex justify-between items-center">
                    <h2 className="text-black text-base">
                        {language === 'ar' ? communique.attributes.title_ar : communique.attributes.title_fr}
                    </h2>
                    <button className="text-primary flex items-center gap-x-1 text-xs"   onClick={() => navigate(`/inter-press-release/${communique.id}`)}>
                        {t('Lire la suite')}
                    </button>
                </div>
            ))}
        </div>
    );
}


interface FAQItem {
    question: string;
    answer: string | string[];
}

const RecruitmentAndFAQ: React.FC = () => {
const { t } = useTranslation();
const faqItems: FAQItem[] = [
    {
        question: t("Quelles sont les voies ou moyens de soumission de candidature ?"),
        answer: t("- Les candidatures se sont soumis que via le Portail Carrière de la Banque")
    },
    {
        question: t("Comment peut-on créer un compte au niveau du portail carrière ?"),
        answer: [
            t("- Cliquer sur le bouton « s'inscrire » et renseigner les différents champs."),
            t("- Activer votre compte en cliquant sur le lien adressé dans votre messagerie privée")
        ]
    },
    {
        question: t("Comment peut-on postuler à un poste ?"),
        answer: [
            t("- Avant de postuler à un poster il faut d'abord avoir un compte au niveau du portail carrière"),
            t("- Remplir toutes rubriques du formulaire de candidatures et joindre les pièces demandées"),
            t("- Soumettre sa candidature une fois le formulaire rempli")
        ]
    },
    {
        question: t("Comment nous rassurer que notre candidature a été soumise ?"),
        answer: t("Vous recevrez un message automatique de confirmation de candidature")
    },
    {
        question: t("Un candidat peut postuler à combien de postes ?"),
        answer: t("- Il n'y a aucune une restriction sur le nombre de candidatures que le candidat peut soumettre")
    },
    {
        question: t("Comment suivre l'évolution de mes candidatures ?"),
        answer: t("- Chaque agent a un compte de messagerie renseignant sur les différentes étapes de l'évolution du processus.")
    }
];

return (
    <div className='flex flex-col gap-6'>
        <div>
            <h2 className="text-2xl font-bold text-primary border-b-2 border-green-500 pb-2 mb-4">{t('Procédure de recrutement')}</h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
                <p className='mb-4'>
                    {t('La politique de recrutement de la Banque Centrale de Mauritanie rentre dans le cadre de la nouvelle stratégie des ressources humaines alignée à la stratégie globale de l\'Institution.')}
                </p>
                <p className='mb-4'>
                    {t('Notre objectif est d\'attirer, retenir et fidéliser les meilleurs talents pour accomplir les missions de la Banque de façon efficace et efficiente.')}
                </p>
                <p className='mb-4'>
                    {t('Dorénavant, tout acte de candidature pour un emploi ou un stage à la Banque Centrale de Mauritanie doit être fait en ligne via la présente application informatique. Chaque candidat doit s\'inscrire et remplir dûment le formulaire d\'inscription.')}
                </p>
                <p className='mb-2'>{t('L\'application permet aux candidats de :')}</p>
                <ul className='list-none mb-4'>
                    <li className='flex items-center'><span className='text-green-500 mr-2'>✓</span> {t('S\'enregistrer dans la base de données;')}</li>
                    <li className='flex items-center'><span className='text-green-500 mr-2'>✓</span> {t('Suivre le niveau d\'évolution de leurs candidatures ;')}</li>
                    <li className='flex items-center'><span className='text-green-500 mr-2'>✓</span> {t('Etre informé des postes vacants à pourvoir ;')}</li>
                </ul>
                <p>
                    {t('Les stagiaires admis à l\'un des programmes de stage acceptent de signer une convention de stage portant les engagements de chaque partie y compris les clauses relatives au respect du secret professionnel et de la confidentialité.')}
                </p>
            </div>
        </div>

        <div>
            <h2 className="text-2xl font-bold text-primary border-b-2 border-green-500 pb-2 mb-4">{t('FAQ')}</h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
                {faqItems.map((item, index) => (
                    <div key={index} className='mb-4'>
                        <h3 className='font-bold text-lg mb-2'>{item.question}</h3>
                        {Array.isArray(item.answer) ? (
                            <ul className='list-none'>
                                {item.answer.map((answerItem, answerIndex) => (
                                    <li key={answerIndex}>{answerItem}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{item.answer}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    </div>
);
};

interface Message {
    id: number;
    text: string;
    sender: 'user' | 'bot';
}

interface ContactProps {
    user: any; // Adjust the type according to your user object structure
}




  


const Contact = ({ user } : any) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user) {
      fetchContacts();
    }
  }, [user]);

  const fetchContacts = async () => {
    try {
      const response = await fetch(`https://api.sitewebbcm.com/api/candidat-contacts?filters[candidat][id][$eq]=${user.id}&sort=createdAt:desc&populate=*`, {
        headers: {
          'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
        }
      });
      const data = await response.json();
      setContacts(data.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleSubmit = async (e : any) => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      const response = await fetch('https://api.sitewebbcm.com/api/candidat-contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
        },
        body: JSON.stringify({
          data: {
            message,
            candidat: user.id,
            status: 'pending'
          }
        })
      });

      if (response.ok) {
        setMessage('');
        fetchContacts();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="flex flex-col h-[600px] w-full mx-auto border border-gray-300 rounded-lg overflow-hidden">
      <div className="bg-primary text-white p-4">
        <h2 className="text-xl font-bold">{t('Contact')}</h2>
      </div>
      <div className="flex-1 overflow-y-auto p-4 bg-gray-100">
        {contacts.map((contact : any) => (
          <div key={contact.id} 
          
          className={contact.attributes.by_admin ? "mb-4 bg-blue-400 text-white p-3 rounded-lg shadow" : "mb-4 bg-white p-3 rounded-lg shadow"}>

            <span className='font-bold'>{contact.attributes.by_admin ? "BCM reponse :" : t('Vous :')}</span>
            <p>{contact.attributes.message}</p>
            {/* {contact.attributes.adminResponse && (
              <div className="mt-2 bg-blue-100 p-2 rounded">
                <p className="font-bold">Admin Response:</p>
                <p>{contact.attributes.adminResponse}</p>
              </div>
            )} */}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="bg-white p-4">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-2"
          placeholder={t("Tapez votre message ici...")}
          disabled={!user}
          rows={3}
        />
        <button
          type="submit"
          className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
          disabled={!user}
        >
          {t('Envoyer')}
        </button>
      </form>
      {!user && (
        <div className="bg-red-100 text-red-700 p-2 text-center">
          {t('Vous devez être connecté pour envoyer un message.')}
        </div>
      )}
    </div>
  );
};

export default Contact;





const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  