import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../components/card';
import { useTranslation } from 'react-i18next';



export function ServicesPage() {

    const navigate = useNavigate();


  
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center p-8">
      <div className="flex flex-col gap-y-4 text-sm max-w-4xl w-full">
        <Card className="w-full xl:w-[800px] " href='/services/order-tracking'
 // Navigate to the new page

        >
          <div className="flex gap-x-4 items-center">
            <p className="text-darkGray font-Montserrat font-semibold">

              {
                t('Suivi des ordres de paiements trésor')
              }
              
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}
