import { ReactNode } from "react";

export function Title({ children }: { children: ReactNode }) {
  return (
    <h1 className="font-Montserrat text-primary text-xl font-semibold uppercase lg:text-2xl">
      {children}
    </h1>
  );
}

export function Stroke({ className }: { className?: string }) {
  return <hr className={`border-strokeGray w-full ${className ?? ""}`} />;
}
