import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowIcon } from "./home_page";
import { useLanguage } from "../context/LanguageContext";
import { Card } from "../components/card";

interface FinancialStatement {
  id: number;
  attributes: {
    name_fr: string;
    name_ar: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    type: string;
  };
}

export function MoneyPage() {
  const { id } = useParams<{ id?: string }>(); // Get the URL parameter
  const [items, setItems] = useState<FinancialStatement[]>([]);
  const [item, setItem] = useState<FinancialStatement | null>(null);


  const {language} = useLanguage();
  useEffect(() => {
    const fetchFinancialStatements = async () => {
      try {
        const url = id
          ? `https://api.sitewebbcm.com/api/Money-and-reforms/${id}?pagination[limit]=-1&populate=*`
          : 'https://api.sitewebbcm.com/api/Money-and-reforms?pagination[limit]=-1&populate=*';

        const response = await fetch(url, {
          headers: {
          }
        });

        const data = await response.json();
        if (id) {
          setItem(data.data);
        } else {
          setItems(data.data);
        }
      } catch (error) {
        console.error('Error fetching financial statements:', error);
      }
    };

    fetchFinancialStatements();
  }, [id]);

  return (
    <div className="flex justify-center items-center p-8">
      <div className="flex flex-col gap-y-4 text-sm lg:items-start lg:justify-center lg:gap-x-8 lg:gap-y-8 max-w-4xl w-full">
          {items.map((item) => {
            const link = `/money-types/${item.id}`;
        
            return (
              <Card
                href={link}
                key={item.id}
                className="w-full xl:w-[800px]"
              >
                <div className="flex gap-x-4 items-center">
                  <p className="text-darkGray font-Montserrat font-semibold">
                    {
                   language === 'fr' ? item.attributes.name_fr : item?.attributes.name_ar}
                  </p>
                </div>
              </Card>
            )}
        )}
      </div>
    </div>
  );
}

