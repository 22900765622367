import React, { useEffect, useState } from 'react';
import { ArrowIcon } from './home_page';

interface Contact {
  id: number;
  attributes: {
    phone: string;
    fax: string;
    email: string;
    name_fr: string;
    address_fr: string;
    name_ar: string;
    address_ar: string;
  };
}

export function ContactPage() {
  const [contact, setContact] = useState<Contact | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const response = await fetch('https://api.sitewebbcm.com/api/Contact', {
          // headers: {
          //   'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
          // }
        });
        const data = await response.json();
        setContact(data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contact information:', error);
        setLoading(false);
      }
    };

    fetchContact();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  if (!contact) {
    return <p>No contact information available.</p>;
  }

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">Contact Information</h1>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-2">{contact.attributes.name_fr}</h2>
        <p className="text-lg mb-2">{contact.attributes.address_fr}</p>
        <p className="text-lg mb-2">Tel: {contact.attributes.phone}</p>
        <p className="text-lg mb-2">FAX: {contact.attributes.fax}</p>
        <p className="text-lg mb-2">Email: <a href={`mailto:${contact.attributes.email}`} className="text-green-500">{contact.attributes.email}</a></p>
      </div>
    </div>
  );
}
