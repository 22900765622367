import React, { useEffect, useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'lucide-react';

interface LinkItem {
  id: number;
  attributes: {
    link: string;
    name: string;
    name_ar: string;
  };
}

interface LinkSection {
  id: number;
  attributes: {
    title_fr: string;
    title_ar: string;
    type: string;
    link_items: {
      data: LinkItem[];
    };
  };
}

export function LinksPage() {
  const [sections, setSections] = useState<LinkSection[]>([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await fetch('https://api.sitewebbcm.com/api/Links?pagination[limit]=-1&populate=*', {
          headers: {}
        });
        const data = await response.json();
        setSections(data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching links:', error);
        setLoading(false);
      }
    };

    fetchLinks();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">{t('Liens utiles')}</h1>
        <div className="space-y-12">
          {sections.map((section) => (
            <div key={section.id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <h2 className="text-xl font-semibold bg-green-600 text-white py-3 px-6">
                {language === 'fr' ? section.attributes.title_fr : section.attributes.title_ar}
              </h2>
              <div className="p-6">
                {section.attributes.type === 'simple-link' ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {section.attributes.link_items.data.map((item) => (
                      <a
                        key={item.id}
                        href={item.attributes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-green-600 hover:text-green-800 transition-colors duration-200"
                      >
                        <ExternalLink className="w-4 h-4 mr-2" />
                        <span>{language === 'fr' ? item.attributes.name : item.attributes.name_ar}</span>
                      </a>
                    ))}
                  </div>
                ) : section.attributes.type === 'table-link' ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Nom')}</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Lien')}</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {section.attributes.link_items.data.map((item) => (
                          <tr key={item.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {language === 'fr' ? item.attributes.name : item.attributes.name_ar}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <a
                                href={item.attributes.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-green-600 hover:text-green-800 flex items-center"
                              >
                                <ExternalLink className="w-4 h-4 mr-2" />
                                <span>{item.attributes.link}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}