import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

interface AdvancedBanknoteProps {
  frontImage: string;
  backImage: string;
}

const AdvancedBanknote: React.FC<AdvancedBanknoteProps> = ({ frontImage, backImage }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let isDragging = false;
    let previousPosition = { x: 0, y: 0 };
    let rotationVelocity = { x: 0 };

    const scene = new THREE.Scene();
    
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setClearColor(0xffffff, 1);

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    camera.position.set(0, 0, 3);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const geometry = new THREE.PlaneGeometry(1.5, 3);

    const frontMaterial = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        frontTexture: { value: new THREE.TextureLoader().load(frontImage) },
        twistAmount: { value: 0 },
      },
      vertexShader: `
        uniform float twistAmount;
        varying vec2 vUv;
        void main() {
          vUv = vec2(1.0 - uv.y, uv.x);
          float angle = twistAmount * position.y;
          float sinAngle = sin(angle);
          float cosAngle = cos(angle);
          vec3 twistedPosition = vec3(
            position.x * cosAngle - position.z * sinAngle,
            position.y,
            position.x * sinAngle + position.z * cosAngle
          );
          gl_Position = projectionMatrix * modelViewMatrix * vec4(twistedPosition, 1.0);
        }`,
      fragmentShader: `
        uniform sampler2D frontTexture;
        varying vec2 vUv;
        void main() {
          vec4 texColor = texture2D(frontTexture, vUv);
          gl_FragColor = texColor;
        }`
    });

    const backMaterial = new THREE.ShaderMaterial({
      uniforms: {
        backTexture: { value: new THREE.TextureLoader().load(backImage) },
        twistAmount: { value: 0 }
      },
      vertexShader: `
        uniform float twistAmount;
        varying vec2 vUv;
        void main() {
          vUv = vec2(1.0 - uv.y, uv.x);
          float angle = twistAmount * position.y;
          float sinAngle = sin(angle);
          float cosAngle = cos(angle);
          vec3 twistedPosition = vec3(
            position.x * cosAngle - position.z * sinAngle,
            position.y,
            position.x * sinAngle + position.z * cosAngle
          );
          gl_Position = projectionMatrix * modelViewMatrix * vec4(twistedPosition, 1.0);
        }`,
      fragmentShader: `
        uniform sampler2D backTexture;
        varying vec2 vUv;
        void main() {
          vec4 texColor = texture2D(backTexture, vUv);
          gl_FragColor = texColor;
        }`,
      side: THREE.BackSide
    });

    const banknote = new THREE.Group();
    const frontMesh = new THREE.Mesh(geometry, frontMaterial);
    const backMesh = new THREE.Mesh(geometry, backMaterial);
    banknote.add(frontMesh);
    banknote.add(backMesh);
    scene.add(banknote);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.rotateSpeed = 0.5;
    controls.enableRotate = false;
    controls.enableZoom = false;
    controls.enablePan = false;

    const onPointerMove = (event: PointerEvent) => {
      if (isDragging) {
        const deltaX = event.clientX - previousPosition.x;
        banknote.rotation.y += deltaX * 0.01;
        frontMaterial.uniforms.twistAmount.value = deltaX * 0.01;
        backMaterial.uniforms.twistAmount.value = deltaX * 0.01;
        rotationVelocity.x = deltaX * 0.01;
        previousPosition.x = event.clientX;
      }
    };

    const onPointerDown = (event: PointerEvent) => {
      isDragging = true;
      previousPosition.x = event.clientX;
      previousPosition.y = event.clientY;
    };

    const onPointerUp = () => {
      isDragging = false;
    };

    renderer.domElement.addEventListener('pointerdown', onPointerDown);
    renderer.domElement.addEventListener('pointermove', onPointerMove);
    renderer.domElement.addEventListener('pointerup', onPointerUp);
    renderer.domElement.addEventListener('pointercancel', onPointerUp);

    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const animate = () => {
      requestAnimationFrame(animate);

      if (!isDragging) {
        banknote.rotation.y += rotationVelocity.x;
        rotationVelocity.x *= 0.95;
        frontMaterial.uniforms.twistAmount.value *= 0.95;
        backMaterial.uniforms.twistAmount.value *= 0.95;
      }

      frontMaterial.uniforms.time.value += 0.01;

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      renderer.domElement.removeEventListener('pointerdown', onPointerDown);
      renderer.domElement.removeEventListener('pointermove', onPointerMove);
      renderer.domElement.removeEventListener('pointerup', onPointerUp);
      renderer.domElement.removeEventListener('pointercancel', onPointerUp);
      window.removeEventListener('resize', handleResize);
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, [frontImage, backImage]);

  return <div ref={containerRef} className="w-full h-64 md:h-96" />;
};

export default AdvancedBanknote;