import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';

interface ArticleData {
  id: number;
  attributes: {
    title_fr: string;
    title_ar: string;
    content_fr: string;
    content_ar: string;
    createdAt: string;
    thumbnail_image: {
      data: { attributes: { url: string; name: string } };
    };
    files_fr: { data: [{ attributes: { url: string; name: string } }] };
    files_ar: { data: [{ attributes: { url: string; name: string } }] };
  };
}

const ArticlePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const [article, setArticle] = useState<ArticleData | null>(null);

  useEffect(() => {
    fetch(`https://api.sitewebbcm.com/api/news/${id}?populate=*`, {
      // headers: {
      //   Authorization: `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`,
      // },
    })
      .then((response) => response.json())
      .then((data) => setArticle(data.data))
      .catch((error) => console.error('Error fetching article:', error));
  }, [id]);

  if (!article) {
      return (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
        </div>
      );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">
        {language === 'fr' ? article.attributes.title_fr : article.attributes.title_ar}
      </h1>
      <p className="text-gray-600 mb-4">
        {new Date(article.attributes.createdAt).toLocaleDateString()}
      </p>
      <div className="mb-4 flex justify-center items-center">
        <img
          src={`https://api.sitewebbcm.com${article?.attributes?.thumbnail_image?.data?.attributes.url}`}
          alt={language === 'fr' ? article.attributes.title_fr : article.attributes.title_ar}
          className="w-auto h-64 rounded mb-2"
        />
      </div>
      <div className="prose mb-8">
        <p>
          {language === 'fr' ? article.attributes.content_fr : article.attributes.content_ar}
        </p>
      </div>
      {article.attributes.files_fr.data.length > 0 && (
        <div className="mt-8">
          <ul>
            {(language === 'fr' ? article.attributes.files_fr : article.attributes.files_ar).data.map(
              (file, index) => (
                <li key={index} className="mb-2">
                  <a
                    href={`https://api.sitewebbcm.com${file.attributes.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                  >
                    {file.attributes.name}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
