import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./ROUTES/home_page";
import Layout from "./ROUTES/layout";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { MoneyPage, PiecesPage } from "./ROUTES/money_page";
import AllNews from "./ROUTES/news";
import ArticlePage from "./ROUTES/article_page";
import GenericArticlePage from "./ROUTES/generic_article";
import FinancialPage from "./ROUTES/financial_page";
import { ServicesPage } from "./ROUTES/services_page";
import { OrderTrackingPage } from "./components/order_tracking_page";
import { LinksPage } from "./ROUTES/links_page";
import { ContactPage } from "./ROUTES/conatct_page";
import SectionPage from "./ROUTES/section_page";
import { CareerPage } from "./ROUTES/career_page";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from 'react-i18next';
import { LanguageProvider } from '../src/context/LanguageContext'; 
import i18n from './i18n'; 
import MoneyRateTable from "./ROUTES/money_rate_table";
import { MoneyTypePage } from "./ROUTES/money_type_page";
import GenericArticleListing from "./ROUTES/generic_listing";
import { MoneyHistoriquePage } from "./ROUTES/money_historique_page";
import { TOKEN_KEY } from "./constants";
import { LoadingProvider } from "./context/LoadingContext";
import SearchResults from "./ROUTES/search_reult";
import RenformesCompontListing from "./ROUTES/renformes";
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const originalFetch = window.fetch;

const SEO = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <LoadingProvider>
        <LanguageProvider>
          <BrowserRouter>
            <ScrollToTop />
            <SEO title="Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Site officiel de la Banque Centrale de Mauritanie" />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<>
                  <SEO title="Accueil | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Bienvenue sur le site officiel de la Banque Centrale de Mauritanie" />
                  <HomePage />
                </>} />
                <Route path="/money-types/:id" element={<>
                  <SEO title="Types de Monnaie | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Explorez les différents types de monnaie" />
                  <MoneyTypePage />
                </>} />
                <Route path="/monnaie" element={<>
                  <SEO title="Monnaie | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations sur la monnaie mauritanienne" />
                  <MoneyPage />
                </>} />
                <Route path="/monnaie/:id" element={<>
                  <SEO title="Détails de la Monnaie | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations détaillées sur une monnaie spécifique" />
                  <MoneyPage />
                </>} />
                <Route path="/contacts" element={<>
                  <SEO title="Contactez-nous | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Nos coordonnées et formulaire de contact" />
                  <ContactPage />
                </>} />          
                <Route path="/news" element={<>
                  <SEO title="Actualités | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Dernières nouvelles et mises à jour" />
                  <AllNews />
                </>} />
                <Route path="/news/:id" element={<>
                  <SEO title="Article d'actualité | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Lire notre dernier article d'actualité" />
                  <ArticlePage />
                </>} />
                <Route path="/inter-press-release/:id" element={<>
                  <SEO title="Communiqué de Presse | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Lire notre dernier communiqué de presse" />
                  <GenericArticlePage type={"Press-relases-interns"} />
                </>} />
                <Route path="/offers-calls" element={<>
                  <SEO title="Offres et Appels | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Consultez nos offres et appels d'offres actuels" />
                  <GenericArticleListing type={"offers-calls"} />
                </>} />
                <Route path="/offers-calls/:id" element={<>
                  <SEO title="Détails de l'Offre | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations détaillées sur une offre ou un appel d'offres spécifique" />
                  <GenericArticlePage type={"offers-calls"} />
                </>} />
                <Route path="/renformes" element={<>
                  <SEO title="Réformes | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations sur les réformes bancaires" />
                  <RenformesCompontListing  type={'renformes'} />
                </>} />
                <Route path="/renformes/:id" element={<>
                  <SEO title="Détails de la Réforme | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations détaillées sur une réforme spécifique" />
                  <GenericArticlePage type={"renformes"} />
                </>} />
                <Route path="/search" element={<>
                  <SEO title="Résultats de Recherche | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Trouvez ce que vous cherchez sur notre site" />
                  <SearchResults/>
                </>} />
                <Route path="/money-historique/:id" element={<>
                  <SEO title="Historique de la Monnaie | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Données historiques pour une monnaie spécifique" />
                  <MoneyHistoriquePage/>
                </>} />
                <Route path="/press-release/:id" element={<>
                  <SEO title="Communiqué de Presse | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Lire notre dernier communiqué de presse" />
                  <GenericArticlePage type={"Press-relases-articles"} />
                </>} />
                <Route path="/section/:id" element={<>
                  <SEO title="Section | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Explorez cette section de notre site" />
                  <SectionPage  />
                </>} />
                <Route path="/pages/:title" element={<>
                  <SEO title="Page | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Consultez cette page sur notre site" />
                  <SectionPage  />
                </>} />
                <Route path="/financial-repports" element={<>
                  <SEO title="Rapports Financiers | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Consultez nos rapports financiers" />
                  <FinancialPage />
                </>} />
                <Route path="/financial-repports/:id" element={<>
                  <SEO title="Détails du Rapport Financier | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Informations détaillées sur un rapport financier spécifique" />
                  <FinancialPage />
                </>} />
                <Route path="/services" element={<>
                  <SEO title="Nos Services | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Découvrez les services que nous proposons" />
                  <ServicesPage />
                </>} />
                <Route path="/services/order-tracking" element={<>
                  <SEO title="Suivi de Commande | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Suivez le statut de votre commande" />
                  <OrderTrackingPage/>
                </>} />
                <Route path="/links" element={<>
                  <SEO title="Liens Utiles | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Collection de liens utiles" />
                  <LinksPage />
                </>} />
                <Route path="/money-rate-table" element={<>
                  <SEO title="Tableau des Taux de Change | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Taux de change actuels" />
                  <MoneyRateTable />
                </>} />
                <Route path="/careers" element={<>
                  <SEO title="Carrières | Banque Centrale de Mauritanie - البنك المركزي الموريتاني" description="Rejoignez notre équipe - opportunités de carrière" />
                  <CareerPage />
                </>} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
            <Toaster />
          </BrowserRouter>
        </LanguageProvider>
      </LoadingProvider>
    </I18nextProvider>
  </React.StrictMode>,
);

reportWebVitals();

window.fetch = async (resource, options = {}) => {
  options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${TOKEN_KEY}`,
  };

  return originalFetch(resource, options);
};