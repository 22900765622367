import React, { useState, useEffect } from 'react';
import { NewsItem } from '../models/news';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { ArrowIcon } from './home_page';



const AllNews: React.FC = () => {

  const { language } = useLanguage();
  const { t } = useTranslation();
  const [news, setNews] = useState<NewsItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://api.sitewebbcm.com/api/news?populate=*' , 
      {
        headers : {
        }
      }
    )
      .then((response) => response.json())
      .then((data) => setNews(data.data))
      .catch((error) => console.error('Error fetching news:', error));
  }, []);


  const navigateToDetail = (id: number) => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="p-4 lg:p-8">
      <h1 className="text-2xl font-bold mb-6">
        {t('Tout l\'actualité')}
        </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {news.map((item) => (
          <div key={item.id} className="border rounded-lg overflow-hidden shadow-md">
            <img
              src={'https://api.sitewebbcm.com' + item.attributes.thumbnail_image.data.attributes.url}
              alt={item.attributes.title_fr}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <span className="text-xs text-gray-500">{new Date(item.attributes.createdAt).toLocaleDateString()}</span>
              <h2 className="text-lg font-semibold mt-2 line-clamp-2">{
              language === 'fr' ? item.attributes.title_fr :
              item.attributes.title_ar}</h2>
              <p className="text-sm text-gray-700 mt-2 line-clamp-3">{
              language === 'fr' ? item.attributes.content_fr :
              item.attributes.content_ar}</p>
              <button className="mt-4 text-primary flex items-center gap-x-2 text-sm" onClick={() => navigateToDetail(item.id)}>
                


                <span>

                {t('Lire la suite')}
                </span>
          
               

                <ArrowIcon direction={language === 'ar' ? 'left' : 'right'} className="w-3 h-3 stroke-primary" />

              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllNews;
