import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";
import { useParams } from "react-router-dom";
import { Title } from "../components/components";
import { Loader2 } from 'lucide-react';
import AdvancedBanknote from "../components/advanced_bank_note";

const ImageWithSpinner = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader2 className="animate-spin text-primary" size={48} />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-contain ${isLoading ? 'opacity-0' : 'opacity-100'}`}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

const FlippableImage = ({ frontImage, backImage }) => {
  return (
    <div className="relative w-full h-52 [perspective:1000px] group">
      <div className="absolute w-full h-full transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        <div className="absolute w-full h-full backface-hidden">
          <ImageWithSpinner src={frontImage} alt="Front" />
        </div>
        <div className="absolute w-full h-full [transform:rotateY(180deg)] backface-hidden">
          <ImageWithSpinner src={backImage} alt="Back" />
        </div>
      </div>
    </div>
  );
};

export const MoneyHistoriquePage = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedVersions, setSelectedVersions] = useState([]);

  useEffect(() => {
    fetch(`https://api.sitewebbcm.com/api/Money-versions?filters[money_type][id][$eq]=${id}&populate[money_type][populate]=*&populate[fr_image][populate]=*&populate[ar_image][populate]=*`)
      .then(response => response.json())
      .then(({ data }) => {
        const sortedVersions = data.sort((a, b) => {
          const nameA = Number(a.attributes.year.toLowerCase());
          const nameB = Number(b.attributes.year.toLowerCase());
        
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
        setSelectedVersions(sortedVersions);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching money and reform:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin text-primary" size={48} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Title className="text-2xl md:text-3xl font-bold text-center mb-8">
        {t('Historiques')} {selectedVersions[0]?.attributes?.money_type?.data?.attributes?.name_fr} {t('MRU')}
      </Title>

      <div className="mb-16 relative">
        <div className="absolute left-0 top-0 h-full w-1 bg-gray-200" />
        <div className="relative pl-8">
          <div className="absolute -left-2.5 top-2.5 w-6 h-6 rounded-full bg-primary border-4 border-white" />
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-4 md:p-6">
              <h3 className="text-lg font-semibold mb-2">{t('Billet interactif')}</h3>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  {selectedVersions[0]?.attributes?.is_ticket || selectedVersions[0]?.attributes?.is_ticket == null  ? (
                    <AdvancedBanknote 
                      frontImage={`https://api.sitewebbcm.com${selectedVersions[0]?.attributes?.money_type?.data?.attributes?.fr_image?.data?.attributes?.url}`} 
                      backImage={`https://api.sitewebbcm.com${selectedVersions[0]?.attributes?.money_type?.data?.attributes?.ar_image?.data?.attributes?.url}`} 
                    />
                  ) : (
                    <FlippableImage 
                      frontImage={`https://api.sitewebbcm.com${selectedVersions[0]?.attributes?.money_type?.data?.attributes?.fr_image?.data?.attributes?.url}`}
                      backImage={`https://api.sitewebbcm.com${selectedVersions[0]?.attributes?.money_type?.data?.attributes?.ar_image?.data?.attributes?.url}`}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 md:pl-4">
                  <p className="text-gray-600">

                    {language === 'fr' ? selectedVersions[0]?.attributes?.money_type?.data?.attributes?.description_fr || t('Aucune description disponible.') : selectedVersions[0]?.attributes?.money_type?.data?.attributes?.description_ar || t('Aucune description disponible.')}

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="relative">
        {selectedVersions.map((version, index) => {
          const frontImage = `https://api.sitewebbcm.com${language === 'fr' ? version?.attributes?.fr_image?.data?.attributes?.url : version?.attributes?.ar_image?.data?.attributes?.url}`;
          const backImage = `https://api.sitewebbcm.com${language === 'fr' ? version?.attributes?.ar_image?.data?.attributes?.url : version?.attributes?.fr_image?.data?.attributes?.url}`;

          return (
            <div key={index} className="mb-16 relative">
              <div className="absolute left-0 top-0 h-full w-1 bg-gray-200" />
              <div className="relative pl-8">
                <div className="absolute -left-2.5 top-2.5 w-6 h-6 rounded-full bg-primary border-4 border-white" />
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="p-4 md:p-6">
                    <h2 className="text-xl md:text-2xl font-bold mb-4">
                      {t('l\'Année')} {version?.attributes?.year} - {version?.attributes?.old_name}
                    </h2>
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className="w-full md:w-1/2 mb-4 md:mb-0">
                        {version?.attributes?.is_ticket || version?.attributes?.is_ticket == null ? (
                          <AdvancedBanknote frontImage={frontImage} backImage={backImage} />
                        ) : (
                          <FlippableImage frontImage={frontImage} backImage={backImage} />
                        )}
                      </div>
                      <div className="w-full md:w-1/2 md:pl-4">
                        <p className="text-gray-600">
                          {language === 'fr' ? version?.attributes?.description_fr || t('Aucune description disponible.') : version?.attributes?.description_ar || t('Aucune description disponible.')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};