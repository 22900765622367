import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Card } from '../components/card';

interface ContentChild {
  text: string;
  type: string;
}

interface ContentBlock {
  type: string;
  children: ContentChild[];
}

interface ImageFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
  sizeInBytes: number;
}

interface ImageAttributes {
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  formats: {
    large: ImageFormat;
    small: ImageFormat;
    medium: ImageFormat;
    thumbnail: ImageFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: string;
  updatedAt: string;
}

interface FileAttributes {
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number | null;
  height: number | null;
  formats: any | null;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: string;
  updatedAt: string;
}

interface PageAttributes {
  title_fr: string;
  title_ar: string;
  content_fr: string;
  content_ar: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  section_pages: {
    data: {
      id: number;
      attributes: PageAttributes;
    }[];
  };
  images: { data: { id: number; attributes: ImageAttributes }[] };
  files_ar: { data: { id: number; attributes: FileAttributes }[] };
  files_fr: { data: { id: number; attributes: FileAttributes }[] };
  section_page: any;
  section: { data: { id: number; attributes: { title_fr: string; title_ar: string; createdAt: string; updatedAt: string } } }
}

interface SectionAttributes {
  id: number;
  title_fr: string;
  title_ar: string;
  createdAt: string;
  updatedAt: string;
  content_fr: string;
  content_ar: string;
  title: string;
  section_pages: {
    data: {
      id: number;
      attributes: PageAttributes;
    }[];
  };
  children: {
    data: {
      id: number;
      attributes: SectionAttributes;
    }[];
  },
  images: { data: { id: number; attributes: ImageAttributes }[] };
  files_ar: { data: { id: number; attributes: FileAttributes }[] };
  files_fr: { data: { id: number; attributes: FileAttributes }[] };
  section_page: any;
  section: { data: { id: number; attributes: { title_fr: string; title_ar: string; createdAt: string; updatedAt: string } } }
}

interface ApiResponse {
  data: {
    id: number;
    attributes: SectionAttributes;
  }[] | {
    id: number;
    attributes: PageAttributes;
  };
  meta: any;
}

const ContentDisplay: React.FC<{ content: string }> = ({ content }) => {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

const SectionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSection, setIsSection] = useState(false);
  const location = useLocation();
  const { language } = useLanguage();
  const { t } = useTranslation();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      const path = location.pathname.split('/');
      const isPageRoute = path[1] === 'section';
      setIsSection(!isPageRoute);

      const fetchID = isPageRoute ? id : path[2];

      const endpoint = isPageRoute
        ? `https://api.sitewebbcm.com/api/Section-Pages/${fetchID}?pagination[limit]=-1&populate=*`
        : `https://api.sitewebbcm.com/api/Sections/${fetchID}?pagination[limit]=-1&populate=*`;

      try {
        const response = await fetch(endpoint, {
          headers: {
          }
        });
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, location.pathname]);

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-28">
        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status" />
      </div>
    );
  }

  if (!data || (Array.isArray(data.data) && data.data.length === 0)) {
    return <div className="text-center py-10">Aucune donnée disponible</div>;
  }

  const content = isSection
    ? (data.data as any)
    : (data.data as { id: number; attributes: PageAttributes; });

  const { attributes } = content;

  const renderSection = () => {
    const { content_fr, content_ar, section_pages, children, files_fr, files_ar } = attributes as SectionAttributes;

    const allItems = [...(children?.data || []), ...(section_pages?.data || [])];
    const totalItems = allItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allItems.slice(indexOfFirstItem, indexOfLastItem);


    return (
      <div>
        <ContentDisplay content={language === 'fr' ? content_fr! : content_ar!} />
        <ul className="flex flex-col gap-2 mt-4">
          {currentItems.map((item) => (
            <Card
              href={`/${item.attributes.section_pages || children?.data.length == 0 ? 'section' : 'pages'}/${item.id}`}
              key={item.id}
            >
              <div className="flex gap-x-4 items-center">
                <p className="text-darkGray font-Montserrat font-semibold text-sm">
                  {language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
                </p>
              </div>
            </Card>
          ))}
        </ul>
        {renderPagination(totalPages)}
        {renderFiles(files_fr, files_ar)}
      </div>
    );
  };

  const renderPage = () => {
    const { content_fr, content_ar, images, files_ar, files_fr } = attributes as PageAttributes;
    return (
      <div className='flex flex-col gap-4'>
        {renderImages(images)}
        <ContentDisplay content={language === 'fr' ? content_fr! : content_ar!} />
        {renderFiles(files_fr, files_ar)}
      </div>
    );
  };

  const renderPagination = (totalPages: number) => {
    return (
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === page ? 'bg-primary text-white' : 'bg-gray-200'
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  const renderImages = (images: { data: { id: number; attributes: ImageAttributes }[] } | undefined) => {
    if (images?.data && images.data.length > 0) {
      return (
        <div className="mt-4 flex gap-2">
          {images.data.map((image) => (
            <img
              key={image.id}
              src={`https://api.sitewebbcm.com${image.attributes.url}`}
              alt={image.attributes.alternativeText || ''}
              className="w-1/2 h-full object-cover rounded-lg"
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const renderFiles = (files_fr: { data: { id: number; attributes: FileAttributes }[] } | undefined, 
                       files_ar: { data: { id: number; attributes: FileAttributes }[] } | undefined) => {
    const files = language === 'fr' ? files_fr : files_ar;
    if (files?.data && files.data.length > 0) {
      return (
        <div className="mt-4">
          <ul className="list-disc list-inside">
            {files.data.map((file) => (
              <li key={file.id} className="mb-2">
                <a
                  href={`https://api.sitewebbcm.com/${file.attributes.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  {file.attributes.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mx-auto sm:p-6 lg:p-8 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 text-primary">
        {language === 'fr' ? attributes.title_fr : attributes.title_ar}
      </h1>
      {isSection ? renderSection() : renderPage()}
    </div>
  );
};

export default SectionPage;