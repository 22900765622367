import React from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { ArrowIcon } from "../ROUTES/home_page";

export function Card({
  children,
  href = "/",
  ...divProps
}: {
  children: React.ReactNode;
  href?: string;
} & React.HTMLProps<HTMLDivElement>) {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const handleClick = () => {
    if (href !== '/') {
      if (href.startsWith('http') || href.startsWith('https')) {
        window.open(href, '_blank');
      } else {
        navigate(href);
      }
    }
  };

  return (
    <div
      {...divProps}
      onClick={handleClick}
      className={`

        cursor-pointer
        relative overflow-hidden border border-strokeGray rounded-lg bg-gradient-to-r 
        from-primary via-[#007934] to-primary p-5 shadow-lg text-white
        transition-all duration-300 ease-in-out
        hover:shadow-xl hover:scale-105 hover:border-white
        group
        ${divProps.className}
      `}
    >
      <div className="relative z-10 flex justify-between items-center">
        <div className="flex flex-col">
          {children}
        </div>
        <ArrowIcon
          direction={language === 'ar' ? 'left' : 'right'}
          className="stroke-white h-6 w-6 stroke-[2px] transition-transform duration-300 ease-in-out
                     group-hover:translate-x-1 group-hover:scale-110"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-r from-primary via-[#007934] to-primary opacity-0 
                      transition-opacity duration-300 ease-in-out group-hover:opacity-20" />
      <div className="absolute inset-0 bg-white opacity-0 transition-opacity duration-300 ease-in-out 
                      group-hover:opacity-10" />
    </div>
  );
}