import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";
import { useEffect, useState } from "react";
import { Money } from "./home_page";




const MoneyRateTable: React.FC = () => {



    const { language } = useLanguage();
    const isRTL = language === 'ar';
  
  
    const { t } = useTranslation();
  
    const [currencies, setCurrencies] = useState<Money[]>([]);
  
    useEffect(() => {
      fetch('https://api.sitewebbcm.com/api/Monies?populate=*&pagination[limit]=-1', {
        headers: {
        }
      })
        .then(response => response.json())
        .then((data: { data: Money[] }) => setCurrencies(data.data))
        .catch(error => console.error('Error fetching currencies:', error));
    }, []);
  


    return (
        <div  className="container mx-auto px-4 sm:px-6 lg:px-8">

                <table className="min-w-full divide-y divide-black mt-6">
        <thead className="bg-primary  ">
          <tr>
            <th className="p-2 text-center text-sm font-medium text-white">{t('QUANTITE')}</th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('DEVISE')} </th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('CODE')}  </th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('COURS DE REFERENCE')} </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-x divide-gray-200">
          {currencies.map(currency => (
            <tr key={currency.id}>
              <td className="p-2  text-center text-sm text-gray-900">{currency.attributes.unity}</td>
              <td className="p-2  text-center text-sm text-gray-900">{currency.attributes.name_fr}</td>
              <td className="p-2 text-center text-sm text-gray-900">{currency.attributes.code}</td>
              <td className="p-2 text-center text-sm text-gray-900">{getTodayValue(currency)}</td>

            </tr>
          ))}
        </tbody>
      </table>

        </div>
    );
};


const getTodayValue = (money: Money): string => {
    const today = new Date().toISOString().split('T')[0];
    const todayChange = money.attributes.money_today_changes.data.find(change => change.attributes.day === today);
    return todayChange ? todayChange.attributes.value : 'N/A';
  };



export default MoneyRateTable;