import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface Video {
  id: number;
  attributes: {
    url: string;
    name: string;
    type: string;
  };
}

interface VideoCarouselProps {
  videos: Video[];
}

const VideoCarousel: React.FC<VideoCarouselProps> = ({ videos }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (videos.length > 0) {
      setLoading(false);
    }
  }, [videos]);

  const CustomPrevArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-r"
      onClick={onClick}
    >
      <svg
        width="20"
        className={`cursor-pointer stroke-white stroke-z w-3 h-3`}
        height="26"
        viewBox="0 0 14 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1L1 13L13 25"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );

  const CustomNextArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-l"
      onClick={onClick}
    >
      <svg
        width="20"
        className={`cursor-pointer stroke-white stroke-z rotate-180 transform w-3 h-3 `}
        height="26"
        viewBox="0 0 14 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1L1 13L13 25"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: false,
    draggable: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status">
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="relative">
        <Slider {...settings}>
          {videos.map((video) => (
            <div key={video.id} className="px-2">
              <iframe
                width="100%"
                height={300}
                src={video.attributes.url}
                title={video.attributes.name}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default VideoCarousel;