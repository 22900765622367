import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowIcon } from "./home_page";
import { Stroke } from "../components/components";
import { useLanguage } from '../context/LanguageContext';
import { Card } from '../components/card';



interface FinancialStatement {
  id: number;
  attributes: {
    name_fr: string;
    name_ar: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    type: string;
    files_fr: { data: Array<{ id: number; attributes: { url: string; name: string; ext: string } }> };
    files_ar: { data: Array<{ id: number; attributes: { url: string; name: string; ext: string } }> };
  };
}

export default function FinancialPage() {
  const { id } = useParams<{ id?: string }>(); // Get the URL parameter
  const [items, setItems] = useState<FinancialStatement[]>([]);
  const [item, setItem] = useState<FinancialStatement | null>(null);

  const {language} = useLanguage();

  useEffect(() => {
    const fetchFinancialStatements = async () => {
      try {
        const url = id
          ? `https://api.sitewebbcm.com/api/Financial-statements/${id}?pagination[limit]=-1&populate=*`
          : 'https://api.sitewebbcm.com/api/Financial-statements?pagination[limit]=-1&populate=*';

        const response = await fetch(url, {
          // headers: {
          //   'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
          // }
        });

        const data = await response.json();
        if (id) {
          setItem(data.data);
        } else {
          setItems(data.data);
        }
      } catch (error) {
        console.error('Error fetching financial statements:', error);
      }
    };

    fetchFinancialStatements();
  }, [id]);

  return (
    <div className="flex justify-center items-center p-8">
      <div className="flex flex-col gap-y-4 text-sm lg:items-start lg:justify-center lg:gap-x-8 lg:gap-y-8 max-w-4xl w-full">
        {id && item ? (

          language == 'fr' ?
          item.attributes.files_fr.data.map((file) => (
            <Card
              href={`https://api.sitewebbcm.com${file.attributes.url}`}
              key={file.id}
              className="w-full xl:w-[800px]"
            >
              <div className="flex gap-x-4 items-center">
                <p className="text-darkGray font-Montserrat font-semibold">
                  {file.attributes.name}
                </p>
                <span className="text-primary font-Montserrat font-semibold uppercase">{file.attributes.ext}</span>
              </div>
            </Card>
          )) :

          item.attributes.files_ar.data.map((file) => (
            <Card
              href={`https://api.sitewebbcm.com${file.attributes.url}`}
              key={file.id}
              className="w-full xl:w-[800px]"
            >
              <div className="flex gap-x-4 items-center">
                <p className="text-darkGray font-Montserrat font-semibold">
                  {file.attributes.name}
                </p>
                <span className="text-primary font-Montserrat font-semibold uppercase">{file.attributes.ext}</span>
              </div>
            </Card>
          ))


        ) : (
          items.map((item) => {
            const hasSingleFile = language == 'fr' ? item.attributes.files_fr.data.length == 1 : item.attributes.files_ar.data.length == 1;
            const fileLinks : any= hasSingleFile ? (language == 'fr' ? item.attributes.files_fr : item.attributes.files_ar.data) : [];
            const fileLink = fileLinks.length > 0 ? fileLinks[0].attributes.url : '';
            
            return (
              <Card
                href={hasSingleFile  && fileLink ? `https://api.sitewebbcm.com${fileLink}` : `/financial-repports/${item.id}`}
                key={item.id}
                className="w-full xl:w-[800px]"
              >
                
                <div className="flex gap-x-4 items-center">
                  <p className="text-darkGray font-Montserrat font-semibold">
                    {
                    language == 'fr' ?
                    item.attributes.name_fr : item.attributes.name_ar}

                    {hasSingleFile && ( 
                    
                    <span className="pl-4 text-primary font-Montserrat font-semibold uppercase">{
                      
                      language == 'fr' ?
                      item.attributes.files_fr.data[0].attributes.ext : 
                      

                      item.attributes.files_ar.data[0].attributes.ext 

                      
                      }</span>

                    )
                    }
                  </p>
                </div>
              </Card>
            );
          })
        )}
      </div>
    </div>
  );
}
