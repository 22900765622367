import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import Footer from "../components/footer";
import Navigation from "../components/navbar";
import logo from '../assets/images/logo.png';

interface MenuItem {
  id: number | string;
  name: string;
  title_fr?: string;
  title_ar?: string;
  link: string;
  dropdown?: MenuItem[];
  isExternal?: boolean;
}

interface MoneyReformSection {
  title_fr: string;
  title_ar: string;
  money_and_reforms: {
    data: {
      id: number;
      attributes: {
        title_fr: string;
        title_ar: string;
      };
    }[];
  };
}

const HEADER_ITEMS: { [key: string]: string } = {
  Services: "/services",
  Liens: "/links",
};

const transformData = (data: any): MenuItem[] => {
  const transformItem = (item: any, isTopLevel: boolean = false, isSectionPage: boolean = false): MenuItem => {
    const result: MenuItem = {
      id: item.id,
      name: item.attributes.title_fr,
      title_fr: item.attributes.title_fr,
      title_ar: item.attributes.title_ar,
      link: isTopLevel ? `/pages/${item.id}` :
        isSectionPage ? `/section/${item.id}` : `/pages/${item.id}`,
    };

    const sectionPages = item.attributes.section_pages?.data.map((child: any) =>
      transformItem(child, false, true)
    ) || [];

    const children = item.attributes.children?.data.map((child: any) =>
      transformItem(child, false, false)
    ) || [];

    const allChildren = [...sectionPages, ...children];

    if (allChildren.length > 0) {
      result.dropdown = allChildren;
    }

    return result;
  };

  return data.map((item: any) => transformItem(item, true, false));
};

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { t, i18n } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const [direction, setDirection] = useState('ltr');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setDirection(language === 'ar' ? 'rtl' : 'ltr');
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      setIsLoading(true);
      try {
        const [sectionsResponse, moneyReformResponse] = await Promise.all([
          fetch('https://api.sitewebbcm.com/api/Sections?filters[is_in_menu][$eq]=true&pagination[limit]=-1&populate[children][populate]=*&populate[section_pages]=*'),
          fetch('https://api.sitewebbcm.com/api/money-and-reform-sections?populate=*')
        ]);
  
        const sectionsData = await sectionsResponse.json();
        const moneyReformData: { data: Array<{ attributes: MoneyReformSection }> } = await moneyReformResponse.json();
  
        const topLevelSections = sectionsData.data.filter((section: any) =>
          !sectionsData.data.some((s: any) => s.attributes.children.data.some((child: any) => child.id === section.id))
        );
        const transformedData = transformData(topLevelSections);
  
        transformedData.sort((a, b) => {
          if (a.name === "Présentation") return -1;
          if (b.name === "Présentation") return 1;
          return 0;
        });
  
        const moneyReformDropdowns = moneyReformData.data.map(section => ({
          id: `money-reform-${section.id}`,
          name: section.attributes.title_fr,
          title_fr: section.attributes.title_fr,
          title_ar: section.attributes.title_ar,
          link: section.attributes.is_money ? `/monnaie` : `renformes`,
          dropdown: section.attributes.is_money ? section.attributes.money_and_reforms.data.map(item => ({
            id: item.id,
            name: item.attributes.name_fr,
            title_fr: item.attributes.name_fr,
            title_ar: item.attributes.name_ar,
            link: `/money-types/${item.id}`,
            isExternal: false
          })) : section.attributes.renformes.data.map(item => ({
            id: item.id,
            name: item.attributes.title_fr,
            title_fr: item.attributes.title_fr,
            title_ar: item.attributes.title_ar,
            link: `renformes/${item.id}`,
            isExternal: false
          }))
        }));
  
        const moneyReformMenuItem: MenuItem = {
          id: 'money-reform',
          name: "Monnaie et Réformes",
          title_fr: "Monnaie et Réformes",
          title_ar: "النقود والإصلاحات",
          link: '/monnaie',
          dropdown: moneyReformDropdowns
        };
  
        const staticHeaderItems: any = Object.entries(HEADER_ITEMS).map(([name, link], index) => ({
          id: `static-${index}`,
          name,
          title_ar: t(name),
          title_fr: t(name),
          link,
          isExternal: link.startsWith('http')
        }));
  
        setMenuItems([...transformedData, moneyReformMenuItem, ...staticHeaderItems]);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchMenuItems();
  }, [t]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="relative">
          <img 
            src={logo} 
            alt="Loading..." 
            className="w-32 h-32 animate-pulse"
          />
          <div className="absolute -inset-6  border-t-4 border-green-500 rounded-full animate-spin p-24"></div>
        </div>
      </div>
    );
  }

  return (
    <div dir={direction}>
      <Navigation menuItems={menuItems} />

      <main className="container mx-auto px-4 py-8 min-h-[80vh]">
        <Outlet />
      </main>

      <Footer headerItems={HEADER_ITEMS} menuItems={menuItems} />
    </div>
  );
};

export default Layout;