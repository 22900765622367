import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Check local storage for a saved language preference
    const savedLanguage = localStorage.getItem('appLanguage');
    return savedLanguage ? savedLanguage : 'fr'; // Default to 'fr' if no preference is found
  });

  useEffect(() => {
    // Update local storage whenever the language changes
    localStorage.setItem('appLanguage', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};