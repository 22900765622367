import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { Title } from '../components/components';
import { Card } from '../components/card';

interface GenericArticleData {
  id: number;
  attributes: {
    [key: string]: string | null | { data: { attributes: { url: string; name: string } } | null } | { data: FileData[] } | FileData[] | null;
  };
}

interface ArticleListingProps {
  type: string; // Type passed as a prop
}

const fecthData = async (type: string) => {
  const response = await fetch(`https://api.sitewebbcm.com/api/${type}?populate=*`, {
  
  });
  return response.json();
};

const RenformesCompontListing: React.FC<any> = ({ type }) => {
  const [articles, setArticles] = useState<any[] | null>(null);
  const { t } = useTranslation();
  const { language } = useLanguage();

  useEffect(() => {
    if (!type) return;

    fecthData(type)
      .then((data) => setArticles(data.data))
      .catch((error) => console.error('Error fetching articles:', error));
  }, [type]);

  if (!articles) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  return (


    <div className="mx-auto sm:p-6 lg:p-8 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
 <div className="flex flex-col  p-4">


{/* <Title>{t('Appels d’offres')} :</Title> */}

 <ul className='mt-5'>
   {articles.map((article) => {
     const title = language == 'fr' ? article.attributes.title_fr : article.attributes.title_ar as string;
   return (
   <Card
       href={`/${type}/${article.id}`}
       key={article.id}
     >
       <div className="flex gap-x-4 items-center">
         <p className="text-darkGray font-Montserrat font-semibold text-sm">
           {language === 'fr' ? article.attributes.title_fr : article.attributes.title_ar}
         </p>
       </div>
     </Card>
     );
   })}
 </ul>
</div>

    </div>
   
  );
};

export default RenformesCompontListing;