import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowIcon } from '../ROUTES/home_page';

interface Image {
  id: number;
  attributes: {
    url: string;
    name: string;
    type: string;
  };
}

interface ImageCarouselProps {
  images: Image[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const CustomPrevArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-r"
      onClick={onClick}
    >
      <ArrowIcon className="stroke-white" />
    </button>
  );

  const CustomNextArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-l"
      onClick={onClick}
    >
      <ArrowIcon direction="right" className="stroke-white" />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: false,
    draggable: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="relative">
        <Slider {...settings}>
          {images?.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image.attributes.url}
                alt={image.attributes.url}
                className="w-full h-80 object-cover rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageCarousel;