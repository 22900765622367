import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';

interface FileData {
  attributes: {
    url: string;
    name: string;
  };
}

interface GenericArticleData {
  id: number;
  attributes: {
    [key: string]: string | null | { data: { attributes: { url: string; name: string } } | null } | { data: FileData[] } | FileData[] | null;
  };
}

interface ArticlePageProps {
  type: string; // Type passed as a prop
}

const fetchArticleData = async (type: string, id: string) => {
  const response = await fetch(`https://api.sitewebbcm.com/api/${type}/${id}?populate=*`, {
    // headers: {
    //   'Authorization': `Bearer 7cac421ad3c41b986837568409bea017df90a9db55975075dd83f4f74ecb92d8c73424ba9d5c37634dbb3a50381fde661b65f296bb4882525609c2760e86321c6929adaf54757484d24287dda7dba5a13b83282ecd4191957d56555fe1f6c0bbab47e725c801868b0f0c10db43873262381f6b10677112cf6d55e259b476a475`
    // },
  });
  return response.json();
};

const GenericArticlePage: React.FC<ArticlePageProps> = ({ type }) => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<GenericArticleData | null>(null);
  const { t } = useTranslation();
  const { language} = useLanguage();

  useEffect(() => {
    if (!type || !id) return;
    
    fetchArticleData(type, id)
      .then((data) => setArticle(data.data))
      .catch((error) => console.error('Error fetching article:', error));
  }, [id, type]);

  if (!article) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  const title = language == 'fr' ? article.attributes.title_fr : article.attributes.title_ar as string | undefined;
  const createdAt = article.attributes.createdAt as string | undefined;
  const topImage = article.attributes.top_image as { data: { attributes: { url: string; name: string } } | null } | undefined;
  const content = language == 'fr' ? article.attributes.content_fr : article.attributes.content_ar as string | null;
  const files = article.attributes.files_fr as { data: FileData[] } | undefined;
  const filesAr = article.attributes.files_ar as { data: FileData[] } | undefined;

  return (
    <div className="container mx-auto p-4">
      {title && <h1 className="text-3xl font-bold mb-4">{title}</h1>}
      {createdAt && <p className="text-gray-600 mb-4">{new Date(createdAt).toLocaleDateString()}</p>}
      
      {topImage && topImage?.data && (
        <div className="mb-4 flex justify-center items-center">
          <img
            src={`${topImage.data.attributes.url}`}
            alt={t('Top Image')}
            className="w-auto h-64 rounded mb-2"
          />
        </div>
      )}
      
      {content && (
        <div className="prose mb-8">
          <p>{content}</p>
        </div>
      )}
      
      {language == 'fr' && files?.data && files.data.length > 0 && (
        <div className="mt-8">
          <ul>
            {files.data.map((file, index) => (
              <li key={index} className="mb-2">
                <a
                  href={`https://api.sitewebbcm.com/${file.attributes.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  {file.attributes.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {language == 'ar' && filesAr?.data && filesAr.data.length > 0 && (
        <div className="mt-8">
          <ul>
            {filesAr.data.map((file, index) => (
              <li key={index} className="mb-2">
                <a
                  href={`https://api.sitewebbcm.com/${file.attributes.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  {file.attributes.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GenericArticlePage;
